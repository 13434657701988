
import { defineComponent, inject, ref, Ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import rmaDetail from "@/graphql/rmaDetail.query.gql";
import { RmaCaseDetail } from "@/types";
import { defaultRmaDetail } from "@/constants";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import Headline from "@/views/tickets/Headline.vue";
import ReturnLetter from "@/views/tickets/ticket_detail/ReturnLetter.vue";
import TicketState from "@/views/tickets/ticket_detail/TicketState.vue";
import TicketItems from "@/views/tickets/ticket_detail/TicketItems.vue";
import Messages from "@/views/tickets/ticket_detail/Messages.vue";
import Instructions from "@/views/tickets/ticket_detail/Instructions.vue";
import SidebarInfo from "@/views/tickets/ticket_detail/SidebarInfo.vue";
import NotFound from "@/components/NotFound.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

interface HandleRmaDetailQuery {
  rmaDetailResult: Readonly<Ref<Readonly<RmaCaseDetail>>>;
  loading: Ref<boolean>;
  error: Ref<null|Error>;
}

interface QueryVariables {
  rmaCaseId: string;
  message?: string;
  lang: string;
}

interface QueryOptions {
  context: {
    headers: {
      "X-Auth-Token": string | null;
    };
  };
}

function handleRmaDetailQuery(
  queryVariables: Ref<QueryVariables>,
  queryOptions: Ref<QueryOptions>,
  cookies: any
): HandleRmaDetailQuery {
  const { result, loading, error, onResult } = useQuery(
    rmaDetail,
    queryVariables,
    queryOptions
  );
  const rmaDetailResult = useResult(result, defaultRmaDetail, data => {
    return {
      ...data.rmaDetail,
      rmaCase: {
        ...data.rmaDetail.rmaCase,
        type: data.rmaDetail.rmaCase.type.toUpperCase(),
        state: data.rmaDetail.rmaCase.state.toUpperCase()
      },
      stateTimeline: data.rmaDetail.stateTimeline.map((state: any) => {
        return {
          ...state,
          label: state.label.toUpperCase()
        };
      })
    };
  });

  onResult(() => {
    if (!cookies.getCookie("orderId"))
      cookies.setCookie("orderId", rmaDetailResult.value.order.originId);
  });

  return {
    rmaDetailResult,
    loading,
    error
  };
}

export default defineComponent({
  name: "TicketDetail",
  components: {
    Headline,
    ReturnLetter,
    TicketState,
    TicketItems,
    Messages,
    Instructions,
    SidebarInfo,
    ClipLoader,
    NotFound
  },

  setup() {
    const cookies = inject("cookies") as any;
    const ticketId = useRoute().params.tid as string;
    const { t, locale: lang } = useI18n();
    const queryVariables = ref({
      rmaCaseId: ticketId,
      lang: lang
    });

    const queryOptions = ref({
      context: {
        headers: {
          "X-Auth-Token": cookies.getCookie("token")
        }
      }
    });

    const { rmaDetailResult, loading, error } = handleRmaDetailQuery(
      queryVariables,
      queryOptions,
      cookies
    );

    return {
      rmaDetailResult,
      loading,
      error,
      queryVariables,
      t
    };
  }
});
