import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sidebar-info" }
const _hoisted_2 = { class: "ticket-details" }
const _hoisted_3 = { class: "detail" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col1" }
const _hoisted_6 = { class: "col2 bold" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col1" }
const _hoisted_9 = { class: "col2" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col1" }
const _hoisted_12 = { class: "col2" }
const _hoisted_13 = { class: "detail" }
const _hoisted_14 = {
  key: 0,
  class: "row"
}
const _hoisted_15 = { class: "col1" }
const _hoisted_16 = { class: "col2 bold" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col1" }
const _hoisted_19 = { class: "col2" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "col1" }
const _hoisted_22 = { class: "col2" }
const _hoisted_23 = { class: "row" }
const _hoisted_24 = { class: "col1" }
const _hoisted_25 = { class: "col2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CustomCard, null, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("COMMON.DETAILS")), 1)
      ]),
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("ul", _hoisted_3, [
            _createElementVNode("li", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t("COMMON.NAME")), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.data.rmaCase.customer.name), 1)
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.t("COMMON.EMAIL")), 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.data.rmaCase.customer.email), 1)
            ]),
            _createElementVNode("li", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.t("COMMON.PHONE")), 1),
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.data.rmaCase.customer.phone), 1)
            ])
          ]),
          _createElementVNode("ul", _hoisted_13, [
            (_ctx.data.solution.newOrder)
              ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.t("COMMON.NEW_ORDER_NO")), 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.data.solution.newOrder), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("li", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.t("COMMON.DATE_CREATED")), 1),
              _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.data.rmaCase.datetimeCreated), 1)
            ]),
            _createElementVNode("li", _hoisted_20, [
              _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.t("COMMON.IBAN")), 1),
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.formatedIban), 1)
            ]),
            _createElementVNode("li", _hoisted_23, [
              _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.t("COMMON.ORIGINAL_ORDER_NO")), 1),
              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.data.order.originId), 1)
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}