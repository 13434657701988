
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";
import { RmaCaseDetail } from "@/types";

export default defineComponent({
  components: { CustomCard },
  props: {
    data: {
      type: Object as PropType<RmaCaseDetail>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const formatedIban = computed(() =>
      props.data.solution.iban
        ? props.data.solution.iban.replace(/(.{5})/g, "$1 ")
        : ""
    );
    return { t, formatedIban };
  }
});
