import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "picture" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "picture-preview",
  "data-cy": "picture-preview"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseBtn = _resolveComponent("CloseBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imageUrl,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPicturePreviewVisible = true)),
      class: "picture-output"
    }, null, 8, _hoisted_2),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        (_ctx.isPicturePreviewVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.imageUrl,
                class: "picture-big"
              }, null, 8, _hoisted_4),
              _createVNode(_component_CloseBtn, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPicturePreviewVisible = false))
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}