import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "message-row" }
const _hoisted_2 = { class: "message-wrap" }
const _hoisted_3 = { class: "name-and-date" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.message.author.imageUrl !== null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "avatar",
          style: _normalizeStyle('background-image: url(' + _ctx.message.author.imageUrl + ')')
        }, null, 4))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "avatar",
          style: _normalizeStyle({
        'background-image':
          'url(' + require('@/assets/icons/user-default.svg') + ')'
      })
        }, null, 4)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message.author.name), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.message.datetimeCreated), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.message.text), 1)
    ])
  ]))
}