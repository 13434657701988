
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

import CustomCard from "@/components/CustomCard.vue";

export default defineComponent({
  components: { CustomCard },
  props: {
    instructions: {
      type: String,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();

    return {
      t
    };
  }
});
