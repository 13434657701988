
import { defineComponent, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import CloseBtn from "@/components/CloseBtn.vue";

export default defineComponent({
  components: {
    CloseBtn
  },
  props: {
    imageUrl: {
      type: String,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      isPicturePreviewVisible: ref(false) as Ref<boolean>,
      t
    };
  }
});
