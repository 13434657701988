import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "return-quick-actions" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "quick-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createBlock(_component_CustomCard, { optClass: "t-letter" }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("TICKET_DETAIL.LETTER")), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.texts.successMessage), 1),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToView('instructions'))),
            class: "scroll-link"
          }, _toDisplayString(_ctx.t("TICKET_DETAIL.INSTRUCTIONS_TITLE")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollFocusOnMessages())),
            text: _ctx.t('TICKET_DETAIL.ADD_QUESTION'),
            optClass: "question",
            icon: "chat",
            "data-cy": "add-question"
          }, null, 8, ["text"]),
          _createVNode(_component_Button, {
            onClick: _ctx.openInNewTab,
            text: _ctx.t('TICKET_DETAIL.DOWNLOAD'),
            icon: "download",
            "data-cy": "downld-letter"
          }, null, 8, ["onClick", "text"])
        ])
      ])
    ]),
    _: 1
  }))
}