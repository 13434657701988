import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-071df49b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  id: "ticket-detail"
}
const _hoisted_7 = { class: "layout" }
const _hoisted_8 = { class: "content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clip_loader = _resolveComponent("clip-loader")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_Headline = _resolveComponent("Headline")!
  const _component_ReturnLetter = _resolveComponent("ReturnLetter")!
  const _component_TicketState = _resolveComponent("TicketState")!
  const _component_TicketItems = _resolveComponent("TicketItems")!
  const _component_Messages = _resolveComponent("Messages")!
  const _component_Instructions = _resolveComponent("Instructions")!
  const _component_SidebarInfo = _resolveComponent("SidebarInfo")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_clip_loader, {
          color: "#777777",
          size: "100px"
        })
      ]))
    : (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.error.message.includes('401'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : (_openBlock(), _createBlock(_component_NotFound, {
                key: 1,
                type: "error"
              }))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (!_ctx.rmaDetailResult.result)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_NotFound, { type: "noresult" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.t("ERRORS.ERROR_TITLE")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.t("ERRORS.RMA_NOT_FOUND")), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_Headline, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t(`COMMON.RETURN_TYPES.${_ctx.rmaDetailResult.rmaCase.type}.DESCRIPTION`)) + ": " + _toDisplayString(_ctx.rmaDetailResult.rmaCase.code), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ReturnLetter, {
                      data: _ctx.rmaDetailResult,
                      "data-cy": "return-letter"
                    }, null, 8, ["data"]),
                    _createVNode(_component_TicketState, {
                      data: _ctx.rmaDetailResult,
                      "data-cy": "ticket-state"
                    }, null, 8, ["data"]),
                    _createVNode(_component_TicketItems, {
                      data: _ctx.rmaDetailResult,
                      "data-cy": "ticket-items"
                    }, null, 8, ["data"]),
                    _createVNode(_component_Messages, {
                      data: _ctx.rmaDetailResult,
                      queryVariables: _ctx.queryVariables,
                      "data-cy": "messages"
                    }, null, 8, ["data", "queryVariables"]),
                    _createVNode(_component_Instructions, {
                      instructions: _ctx.rmaDetailResult.texts.nextSteps,
                      "data-cy": "instructions"
                    }, null, 8, ["instructions"])
                  ]),
                  _createVNode(_component_SidebarInfo, {
                    data: _ctx.rmaDetailResult,
                    "data-cy": "sidebar"
                  }, null, 8, ["data"])
                ])
              ]))
        ]))
}