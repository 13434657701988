import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "instructions",
  style: {"white-space":"pre-line"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomCard = _resolveComponent("CustomCard")!

  return (_openBlock(), _createBlock(_component_CustomCard, {
    optClass: "t-instructions",
    id: "instructions"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("TICKET_DETAIL.INSTRUCTIONS_TITLE")), 1)
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.instructions), 1)
    ]),
    _: 1
  }))
}