
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import CustomCard from "@/components/CustomCard.vue";
import { RmaCaseDetail, RmaCaseStateTimeline } from "@/types";
import { removeWaitingReplyState } from "@/utils";

export default defineComponent({
  components: { CustomCard },
  props: {
    data: {
      type: Object as PropType<RmaCaseDetail>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const editedStateTimeline: ReadonlyArray<RmaCaseStateTimeline> = removeWaitingReplyState(
      props.data.stateTimeline
    );

    const ticketStateisClosedOrCancelled = Boolean(
      editedStateTimeline.find(
        (stateItem: RmaCaseStateTimeline) =>
          stateItem.label.trim() === "CANCELLED" ||
          stateItem.label.trim() === "CLOSED"
      )
    );

    return {
      t,
      getTrimmedStateStatus: (index: number) =>
        editedStateTimeline[index].status.trim(),
      getTrimmedStateLabel: (index: number) => {
        if (editedStateTimeline[index])
          return editedStateTimeline[index].label.trim();
      },
      editedStateTimeline,
      ticketStateisClosedOrCancelled
    };
  }
});
