
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/Button.vue";
import CustomCard from "@/components/CustomCard.vue";
import { RmaCaseDetail } from "@/types";
import { isCypress } from "@/utils";

export default defineComponent({
  components: { Button, CustomCard },
  props: {
    data: {
      type: Object as PropType<RmaCaseDetail>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();

    const scrollToView = (elementId: string): void => {
      const scrollBehavior: any = isCypress()
        ? {
            block: "nearest"
          }
        : { block: "nearest", behavior: "smooth" };
      window.document.getElementById(elementId)?.scrollIntoView(scrollBehavior);
    };

    const scrollFocusOnMessages = (): void => {
      scrollToView("messages");
      setTimeout(() => {
        (window.document.getElementsByClassName(
          "styled"
        )[0] as HTMLElement).focus();
      }, 500);
    };

    const openInNewTab = (): void => {
      window.open(props.data.rmaCase.claimLetterUrl);
    };

    return { t, scrollToView, scrollFocusOnMessages, openInNewTab };
  }
});
