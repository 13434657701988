
import { defineComponent, inject, PropType, ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import MessageRow from "@/views/tickets/ticket_detail/MessageRow.vue";
import ClickablePicture from "@/components/ClickablePicture.vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomTextArea from "@/components/CustomTextArea.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { RmaCaseDetail, Invalid, HTMLInputEvent } from "@/types";
import { useMutation } from "@vue/apollo-composable";
import rmaMessageCreateMutation from "@/graphql/rmaMessageCreate.mutation.gql";
import rmaDetail from "@/graphql/rmaDetail.query.gql";

interface UploadImages {
  clickOnUpload: Function;
  loadImage: Function;
  messagePictures: Ref<Array<any>>;
  deleteLoadedImage: Function;
}

interface HandleMessagesSending {
  sendMessage: Function;
  newMessage: Ref<string>;
  isMessageInvalid: Ref<Invalid>;
  sendMessageLoading: Ref<boolean>;
}

interface QueryVariables {
  rmaCaseId: string;
  message?: string;
  lang: string;
}

interface QueryOptions {
  context: {
    headers: {
      "X-Auth-Token": string | null;
    };
  };
}

function uploadImages(): UploadImages {
  const messagePictures = ref([]) as Ref<Array<any>>;

  const clickOnUpload = (event: HTMLInputEvent): void => {
    const inputElement = event.target.nextElementSibling as HTMLElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const addEmptyPicture = (): Promise<any> => {
    return new Promise(resolve => {
      resolve(messagePictures.value.push({}));
    });
  };

  const loadImage = (event: HTMLInputEvent) => {
    addEmptyPicture().then(() => {
      const lastImageIndex = messagePictures.value.length - 1;
      const outputImage = document.getElementById(`picture-${lastImageIndex}`)
        ?.firstElementChild as HTMLImageElement;
      if (outputImage && event.target.files) {
        const newSrc = URL.createObjectURL(event.target.files[0]);
        outputImage.src = newSrc;
        outputImage.onload = function() {
          URL.revokeObjectURL(newSrc);
        };
        messagePictures.value[lastImageIndex].file = event.target.files[0];
      }
    });
  };

  const deleteLoadedImage = (event: HTMLInputEvent) => {
    const picIndex = (event.target.parentElement?.parentElement
      ?.id as string).slice(-1);
    messagePictures.value.splice(parseInt(picIndex), 1);
  };
  return { clickOnUpload, loadImage, messagePictures, deleteLoadedImage };
}

function handleMessagesSending(
  queryVariables: QueryVariables,
  queryOptions: Ref<QueryOptions>,
  messagePictures: Ref<Array<any>>,
  t: any
): HandleMessagesSending {
  const newMessage = ref("") as Ref<string>;
  const isMessageInvalid = ref({
    invalid: false,
    reason: t("TICKET_DETAIL.MESSAGES.SENT_EMPTY")
  }) as Ref<Invalid>;

  const {
    mutate: createMessage,
    loading: sendMessageLoading,
    onDone,
    onError
  } = useMutation(rmaMessageCreateMutation, queryOptions);

  const getMessageSendingVariables = () => {
    return newMessage.value !== ""
      ? messagePictures.value.length === 0
        ? {
            ...queryVariables,
            ["message"]: newMessage.value
          }
        : {
            ...queryVariables,
            ["message"]: newMessage.value,
            ["files"]: messagePictures.value.map(
              (object: { file: File }) => object.file
            )
          }
      : {
          ...queryVariables,
          ["files"]: messagePictures.value.map(
            (object: { file: File }) => object.file
          )
        };
  };

  const sendMessage = (): void => {
    if (newMessage.value !== "" || messagePictures.value.length !== 0) {
      createMessage(getMessageSendingVariables(), {
        update: (cache, { data: createMessage }) => {
          const updatedData = createMessage.rmaMessageCreate;
          cache.writeQuery({
            query: rmaDetail,
            variables: queryVariables,
            data: { rmaDetail: updatedData }
          });
        }
      });

      onDone(() => {
        newMessage.value = "";
        messagePictures.value = [];
      });

      onError(() => {
        isMessageInvalid.value.invalid = true;
        isMessageInvalid.value.reason = t(
          "TICKET_DETAIL.MESSAGES.OTHER_REASON"
        );
      });
    } else {
      isMessageInvalid.value.invalid = true;
      isMessageInvalid.value.reason = t("TICKET_DETAIL.MESSAGES.SENT_EMPTY");
      (window.document.getElementsByClassName(
        "styled"
      )[0] as HTMLElement).focus();
    }
  };

  return {
    sendMessage,
    newMessage,
    isMessageInvalid,
    sendMessageLoading
  };
}

export default defineComponent({
  components: {
    MessageRow,
    ClickablePicture,
    CustomCard,
    ClipLoader,
    CustomTextArea,
    CloseBtn
  },
  props: {
    data: {
      type: Object as PropType<RmaCaseDetail>,
      required: true
    },
    queryVariables: {
      type: Object as PropType<{
        rmaCaseId: string;
        lang: string;
      }>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();
    const cookies = inject("cookies") as any;
    const queryOptions = ref({
      context: {
        headers: {
          "X-Auth-Token": cookies.getCookie("token")
        }
      }
    });

    const {
      clickOnUpload,
      loadImage,
      messagePictures,
      deleteLoadedImage
    } = uploadImages();

    const {
      sendMessage,
      newMessage,
      isMessageInvalid,
      sendMessageLoading
    } = handleMessagesSending(
      props.queryVariables,
      queryOptions,
      messagePictures,
      t
    );

    return {
      t,
      sendMessageLoading,
      sendMessage,
      newMessage,
      isMessageInvalid,
      clickOnUpload,
      loadImage,
      messagePictures,
      deleteLoadedImage
    };
  }
});
