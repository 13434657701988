
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ItemDetail from "@/views/tickets/ItemDetail.vue";
import CustomCard from "@/components/CustomCard.vue";
import ClickablePicture from "@/components/ClickablePicture.vue";
import { RmaCaseDetail } from "@/types";

export default defineComponent({
  components: { CustomCard, ClickablePicture, ItemDetail },
  props: {
    data: {
      type: Object as PropType<RmaCaseDetail>,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();

    return { t };
  }
});
