
import { defineComponent, PropType } from "vue";
import { RmaCaseMessage } from "@/types";

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<RmaCaseMessage>,
      required: true
    }
  },
  setup() {
    return {};
  }
});
